import React, {useEffect, useState} from 'react';
import DashboardBlock from "../../../DashboardBlock";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import styles from "./styles.module.scss";
import {Fa} from "../../../elements/fa";
import {faArrowLeft, faSpinner} from "@fortawesome/fontawesome-free-solid";
import {dvirCloseDashboard, dvirOpenDashboard} from "../../../../stores/reducers/dashboardInfo/AC";
import {localStorage} from "../../../../shared/storage";
import {useRedux} from "../../../../states/redux-state";
import {HttpStatus, iDvirInfo, iDvirInspection, iFullStoreState, iHeaderItem} from "../../../../shared/interfaces";
import {dvirInspectionMapping, dvirStatusMapping, fetchDefectPhoto, formatDate, getDvirByKey} from "../utils";
import {Row} from "../../../elements/flex";
import { faChevronRight, faChevronDown } from '@fortawesome/fontawesome-free-solid';
import {ReactComponent as CheckIcon} from '../../../../assets/svg/check-line.svg';
import {ReactComponent as CloseIcon} from '../../../../assets/svg/close-line.svg';
import {ReactComponent as CameraIcon} from '../../../../assets/svg/camera_dvir.svg';
import Modal from "../../../Modal";
import axios from "axios";

interface iDvirProps extends RouteComponentProps<{ id: string }> {}

const mapStateToProps = (state: iFullStoreState, ownProps: iDvirProps) => ({
  dvirId: ownProps.match.params.id,
});

const DvirInspection = ({title, type, headings, notes, odometer, photoUrlHandler, openModalHandler, typeStatus}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isPhotoHeading, setIsPhotoHeading] = useState({});

  const showDefectPhoto = async (id: number, status: string) => {
    const link = await fetchDefectPhoto(id, status);

    photoUrlHandler(link);

    openModalHandler();
  }

  const mapInspectionValue = (value: number) => {
    if (value === 1) return 'Pass';
    else if (value === 0) return 'N/A';
    else return 'Defect';
  }

  const fetchPhotos = async () => {
    const photos = {};

    for (const heading of headings) {
      const value = Number(heading[typeStatus]);

      if (value === 2) {
        const link = await fetchDefectPhoto(Number(heading.id), type);

        if (link) photos[`${heading.id}_${type}`] = true;
      }
    }

    setIsPhotoHeading(photos);
  }

  const checkIfPhoto = (id, type) => {
    return isPhotoHeading[`${id}_${type}`] === true;
  }

  useEffect(() => {
    fetchPhotos();
  }, []);

  return (
    <div className={styles.DvirInspection}>
      <div className={styles.DvirInspectionHeader}>
        {!isExpanded ? <p>{title}</p> : <p></p>}
        <Fa icon={isExpanded ? faChevronDown : faChevronRight} className={styles.DvirInspectionIcon} onClick={() => setIsExpanded(!isExpanded)}/>
      </div>
      {isExpanded && <div className={styles.DvirInspectionSubheader}>
        <h3>{title}</h3>
        <div className={styles.DvirInspectionIcons}>
            <div className={styles.DvirInspectionPassIconContainer}>
                <CheckIcon className={styles.DvirInspectionIcon}/>
                <span>Pass</span>
            </div>
            <div className={styles.DvirInspectionDefectIconContainer}>
                <CloseIcon className={styles.DvirInspectionIcon}/>
                <span>Defect</span>
            </div>
        </div>
      </div>}
      {isExpanded && <table className={styles.DvirInspectionTable}>
        <thead>
          <tr>
            <th>Area</th>
            <th>Condition</th>
          </tr>
        </thead>
        <tbody>
        {headings.map((heading: iHeaderItem) => {
          const value = Number(heading[typeStatus]);

          return (
            <tr>
              <td>{heading.header}</td>
              <td className={styles.DvirCondition}>
                {value === 1 ? <CheckIcon className={styles.DvirInspectionPassIcon}/> : (value === 2 ? <CloseIcon className={styles.DvirInspectionDefectIcon}/> : 'N/A')}
                {value === 2 && checkIfPhoto(heading.id, type) && <CameraIcon className={styles.DvirCameraIcon} onClick={() => showDefectPhoto(Number(heading.id), type)}/>}
              </td>
            </tr>
          )
        })}
        </tbody>
      </table>}
    </div>
  )
}

const DvirItem = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const clientId = localStorage.get('active-client');
  const uid = useRedux((s) => s.auth.user.uid);
  const id = props.dvirId;
  const [dvirInfo, setDvirInfo] = useState<iDvirInfo>();
  const [dvirHeaders, setDvirHeaders] = useState<iHeaderItem[]>();
  const [dvirInspections, setDvirInspections] = useState<iDvirInspection[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [personName, setPersonName] = useState<string>('');
  const people = useSelector<iFullStoreState>(state => state.general.people);
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState<boolean>(false);
  const [photoURL, setPhotoURL] = useState<string>('');

  const mimeToExtension = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/webp': 'webp',
    'image/bmp': 'bmp'
  };

  const getFileExtension = (mimeType: string) => {
    return mimeToExtension[mimeType] || 'jpg';
  }

  const setDefectPhotoURL = (url: string) => {
    setPhotoURL(url);
  }

  const openPhotoModal = () => {
    setIsPhotoModalOpen(true);
  }

  const downloadPhoto = async () => {
    try {
      const response = await axios({
        url: photoURL,
        method: 'GET',
        responseType: 'blob'
      })

      if (response.status === HttpStatus.OK) {
        const mimeType = response.data.type;
        const urlObject = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = urlObject;
        link.setAttribute('download', `defect_${Date.now()}.${getFileExtension(mimeType)}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const getDvir = async () => {
    try {
      setIsLoading(true);

      const data = await getDvirByKey(id);

      setDvirInfo(data.dvir);
      setDvirHeaders(data.header_items);
      setDvirInspections(data.inspections);

      const person = people[data.dvir.person_key];

      if (person && person.displayName) setPersonName(person.displayName);
    }
    catch (e) {
      console.log(e)
    }

    setIsLoading(false);
  }

  useEffect(() => {
    dispatch(dvirOpenDashboard());

    getDvir();

    return () => {
      dispatch(dvirCloseDashboard());
    }
  }, []);

  return (
    <DashboardBlock>
      <div className={styles.DvirItemHeader}>
        <span className="no-print btn-link" style={{paddingLeft: 10, marginRight: 20}} onClick={() => history.goBack()}><Fa
          icon={faArrowLeft}/></span>
      </div>
      {isLoading && (
        <Row justify='center' align='center'>
          <Fa icon={faSpinner} spin style={{ fontSize: 30 }}/>
        </Row>
      )}
      {!isLoading && dvirInfo && <div className={styles.DvirItemContainer}>
        <table className={styles.DvirInfoTable}>
          <tr>
            <td>Vehicle Name</td>
            <td>{dvirInfo.vehicle_name}</td>
          </tr>
          <tr>
            <td>VIN Code</td>
            <td>{dvirInfo.vehicle_vin}</td>
          </tr>
          <tr>
            <td>Driver</td>
            <td>{personName}</td>
          </tr>
          <tr>
            <td>Date</td>
            <td>{formatDate(dvirInfo.created_at)}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{dvirStatusMapping[dvirInfo.status]}</td>
          </tr>
        </table>
        {dvirInspections.length > 0 && <div className={styles.DvirInspections}>
          <h3>Inspections</h3>
          {dvirInspections.map((inspection) => {
            let key;

            if (inspection.type === 'pre-trip-inspection') {
              key = 'pre_status';
            }
            else if (inspection.type === 'post-trip-inspection') {
              key = 'post_status';
            }
            else {
              key = 'maintenance_status';
            }

            return (
              <DvirInspection
                key={inspection.id}
                title={dvirInspectionMapping[inspection.type]}
                type={inspection.type}
                headings={dvirHeaders}
                notes={inspection.notes}
                odometer={inspection.odometer}
                photoUrlHandler={setDefectPhotoURL}
                openModalHandler={openPhotoModal}
                typeStatus={key}
              />
            )
          })}
        </div>}
      </div>}
      {isPhotoModalOpen && <Modal className={styles.DvirPhotoModalContainer}>
        <div className={styles.DvirPhotoModal}>
          <img src={photoURL} className={styles.DvirPhoto}/>
          <div className={styles.DvirPhotoModalBtns}>
            <button onClick={downloadPhoto}>Download</button>
            <button onClick={() => setIsPhotoModalOpen(false)}>Close</button>
          </div>
        </div>
      </Modal>}
    </DashboardBlock>
  )
};

export default connect(mapStateToProps)(DvirItem);

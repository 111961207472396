import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {Actions as GeneralActions} from '../../stores/reducers/general-reducers';

import SearchModal from '../menus/search';
import {iFullStoreState} from '../../shared/interfaces';
import {closeDashboard, openDashboard} from '../../stores/reducers/dashboardInfo/AC';
import {DashboardSize} from '../../stores/reducers/dashboardInfo';
import ChevronRightIcon from '../SVG-components/chevron_right';
import SearchIcon from '../SVG-components/search';
import BurgerMenuIcon from '../SVG-components/burger_menu';
import {ReactComponent as PlusIcon} from '../../assets/svg/plus.svg';
import './styles.scss';
import {isMobile} from '../../shared/helpers';
import { useLocation } from 'react-router-dom';
import { ReactComponent as MaintenanceIcon } from '../../assets/svg/maintanance.svg';
import {GMapModalAC} from "../../stores/reducers/gMapModal/AC";

export const SearchInput = ({visibleInput, extraActions = null}: {visibleInput: boolean, extraActions?: JSX.Element}) => {
    const dispatch = useDispatch();
    const searchTerm = useSelector<iFullStoreState, string>(state => state.general.searchTerm);
    const isSearchVis = useSelector<iFullStoreState, boolean>(s => s.general.showSearch);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(GeneralActions.CHANGE_TERM(e.target.value))
    }

    return (
        <div className="search">
            <div className={`search-input${visibleInput ? ' open': ''}`}>
                {!visibleInput && extraActions}
                {visibleInput && <input type="text" id="search-input" value={searchTerm} onChange={handleChange} />}
                <label htmlFor="search-input" onClick={() => dispatch(GeneralActions.SHOW_SEARCH)}><SearchIcon  /></label>
                {isSearchVis && <label className="close-icon" onClick={() => dispatch(GeneralActions.HIDE_SEARCH)}><PlusIcon  /></label>}
            </div>
        </div>
    );
};

const ToggleBar = ({clickHandler}) => {
  return (
    <div className='toggle-bar'>
      <BurgerMenuIcon onClick={clickHandler}/>
    </div>
  )
}

const DashboardSearchInput = () => {
  const dispatch = useDispatch();
  const searchTerm = useSelector<iFullStoreState, string>(state => state.general.searchTerm);
  const isSearchVis = useSelector<iFullStoreState, boolean>(s => s.general.showSearch);

  const handleChange = (e) => {
    dispatch(GeneralActions.CHANGE_TERM(e.target.value))
  }

  return (
    <div className='dashboard-search-bar'>
      <label
        htmlFor="search-input"
        onClick={() => dispatch(GeneralActions.SHOW_SEARCH)}
      >
        <SearchIcon className='open-svg-icon'/>
      </label>
      <input type='text' id='search-input' value={searchTerm} onChange={handleChange}/>
      {isSearchVis &&
        <label
          className="close-icon"
          onClick={() => {
            dispatch(GeneralActions.CHANGE_TERM(''));
            dispatch(GeneralActions.HIDE_SEARCH);
          }}
        >
          <PlusIcon className='close-svg-icon'/>
        </label>
      }
    </div>
  )
}

type IProps = {
  overlay?: JSX.Element;
  title?: string;
  searchExtra?: JSX.Element;
}
const DashboardBlock: React.FunctionComponent<IProps> = ({children, overlay, title = '', searchExtra = null}) => {
    const size = useSelector<iFullStoreState, DashboardSize>((s) => s.dashboardInfo.size);
    let open = size !== DashboardSize.CLOSED ? ' open' : '';
    const isSearchVis = useSelector<iFullStoreState, boolean>((s) => s.general.showSearch);
    const params = useLocation();
    const dispatch = useDispatch();

    if (size === DashboardSize.OPEN_FULL) {
        open += ' full-size';
    }
    else if (size === DashboardSize.DVIR_FULL) {
        open += ' dvir-full-size';
        dispatch(GMapModalAC.hideModal());
    }

    const ref = useRef(null);
    const [showScroll, setShowScroll] = useState(false);
    const route = ["/billing/units", "/billing/invoices", "/billing/orders"];
    const billingRoute = route.some((item) => item === params.pathname);
    const dvirRoute = params.pathname.includes('/dvir');
    const IS_PUBLIC_VIEW = window.location.href.includes('public-view');
    const [isBookmarksCollapsed, setIsBookmarksCollapsed] = useState<boolean>(false);

    useEffect(() => {
        if (!IS_PUBLIC_VIEW) {
            ref.current.scrollTop = 0;
            setShowScroll(false);
        }
    }, []);

    // !window['cordova']
    return !IS_PUBLIC_VIEW && (
        <div className={`dashboard-block${open}`} onClick={() => setShowScroll(true)}>
			{((isMobile || window['cordova']) && title!='') && <h1>{title}</h1>}
            {(!billingRoute && !dvirRoute && !(isMobile || window['cordova'])) && <SearchInput visibleInput={isSearchVis}/>}
            {!dvirRoute && <ToggleBar clickHandler={() => setIsBookmarksCollapsed(!isBookmarksCollapsed)}/>}
            <PerfectScrollbar className="scrollable" containerRef={(el) => ref.current = el}>
                {isSearchVis ? <SearchModal /> : children}
            </PerfectScrollbar>
            {overlay}
            {!(isMobile) && <DashboardExpanderButtons/>}
            {!billingRoute && !dvirRoute && !(isMobile || window['cordova']) && <div className='bookmarks-bar-container'>
              {(!billingRoute && !(isMobile || window['cordova'])) && <DashboardSearchInput/>}
              {isBookmarksCollapsed && <div className='bookmarks-bar'>
                  <div className='bookmark'>
                      <MaintenanceIcon style={{width: '25px', height: '25px', fill: '#424250'}}/>
                      <p>DVIR</p>
                  </div>
                  <div className='bookmark'>
                  </div>
                  <div className='bookmark'>
                  </div>
                  <div className='bookmark'>
                  </div>
                  <div className='bookmark'>
                  </div>
                  <div className='bookmark'>
                  </div>
                  <div className='bookmark'>
                  </div>
              </div>}
            </div>}
        </div>
    );
};

export default DashboardBlock;

const DashboardExpanderButtons = () => {
    const dispatch = useDispatch();
    const size = useSelector<iFullStoreState, DashboardSize>((s) => s.dashboardInfo.size);

    if (size === DashboardSize.DVIR_FULL) return null;

    return (
        <div className="expander-buttons">
            <div className="increase-button" onClick={() => dispatch(openDashboard())}>
                <ChevronRightIcon />
            </div>
            <span />
            <div className="decrease-button" onClick={() => {dispatch(closeDashboard()); dispatch(GeneralActions.HIDE_SEARCH); }}>
                <ChevronRightIcon />
            </div>
        </div>
    );
};

import React, {CSSProperties as css, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import moment from 'moment';
import { MAP } from 'react-google-maps/lib/constants';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';
import GaugeChart from 'react-gauge-chart';
import {useDispatch, useSelector} from 'react-redux';
import {
    equals,
    prop,
    pathOr,
    pipe,
    mapObjIndexed,
    when,
    unless,
    not,
    always,
    cond,
    T,
    lte,
    lt,
    keys,
    evolve
} from 'ramda';

import DashboardBlock from '../../DashboardBlock';
import ModalHeader from '../../menus/modal/modal-header';
import {Col, Row} from '../../elements/flex';
import {Fa, FaLink} from '../../elements/fa';
import * as devicedb from '../../../shared/db/devices-db';
import C, {ACL, UserCan} from '../../../shared/constants';
import {StandardItem} from '../../general/index';
import {CollapseTile} from '../../general/CollapseItems';
import StandardTile from '../../general/StandardTile';
import MinimalTile from '../../general/MinimalTile';
import * as tagSelectors from '../../../shared/db/tags-labels-selectors';
import {idValArr, friendlyDiff, friendlySpeed} from '../../../shared/helpers';
import {FormatAddress} from './device-list';
import Notepad from '../../general/notepad';
import {AlertToggles} from '../../general/alert-toggle';
import {useRedux, useReduxPath} from '../../../states/redux-state';
import {safeHas} from '../../../shared/ramda';
import {useFirebase} from '../../../states/firebase-state';
import {ExtraInfo} from '../../elements/extra-info';
import Dialog, {DialogConfigSetter} from '../../Dialog';
import {useIsLocating, LOCATION_STATUS} from '../../../stores/reducers/general-reducers';
import {history} from '../../../stores/store';
import {useIsReadOnly} from '../../../hooks/useIsReadOnly';
import DevicesStatusNavigation from './deviceStatuses/DevicesStatusNavigation';
import {Actions as MapActions, defaultBounds} from '../../../stores/reducers/gmap-reducers';
import Captcha from '../../Captcha//Captcha'
import {ReportAC} from '../../../stores/reducers/report-reducers';
import {removeReportTrips} from '../../../stores/reducers/devicesTripsPoints/AC';
import DeviceStatuses from './deviceStatuses/deviceStatuses';
import {
    ItemType,
    iFullStoreState,
    iDeviceDetails,
    iDevicePing,
    iPerson,
    iList,
    iNote,
    MenuPinType,
} from '../../../shared/interfaces';
import {VisibleToPeople} from './DeviceDetails/VisibleToPeople';
import {EngineCodeTable} from './DeviceDetails/EngineCodeTable';
import {CheckEngineOverlay} from './DeviceDetails/CheckEngineOverlay';
import {clientDb} from '../../../shared/firebase';

import HistoryIcon from '../../../assets/svg/history-icon.svg';
import {
    faBullseye,
    faPencilAlt,
    faBatteryEmpty,
    faBatteryFull,
    faBatteryHalf,
    faBatteryThreeQuarters,
    faBatteryQuarter,
    faToggleOn,
    faToggleOff,
    faCircleNotch
} from '@fortawesome/fontawesome-free-solid';

export default ({match: {params: {id: deviceId}}}) => {
    const dispatch = useDispatch();
    const config = useRedux((s) => s.general.config);
    const auth = useRedux((s) => s.auth);
    const {user: authUser, isDriver} = auth;

    const device = useSelector<iFullStoreState, iDeviceDetails | undefined>(
        (state) => state.devicesData.devicesDetails.get(deviceId),
        (l, r) => equals(l, r),
    );
    const currentLabels = useRedux((s) => tagSelectors.getItemLabelNameValMap(s, {type: ItemType.device, itemId: deviceId}));

    const countPerson = useSelector<iFullStoreState, number>((s) => Object.keys(s.general.people).length);

    const assignedPersonId = device?.assignedTo?.personId;
    const assignedPerson = useRedux<iPerson|false>(pathOr(false, ['general', 'people', assignedPersonId]));
    const isDriverDevice = assignedPersonId === authUser.uid && isDriver;

    const people = useRedux((s) => s.general.people);

    const allTags = useRedux((s) => s.general.tags);
    const itemTags = React.useMemo(() => tagSelectors.itemTags(allTags, ItemType.device, deviceId), [allTags, deviceId]);

    const [CEOverlay, setCEOverlay] = React.useState(false);
    const [deviceNotes] = useFirebase<iList<iNote>>(`notes/device/${deviceId}/`, mapObjIndexed(evolve({date: moment})));
    const readOnly = useIsReadOnly(deviceId, ItemType.device)
    const dialogRef = React.useRef<DialogConfigSetter>();
    const [menuPinSettings, setMenuPinSettings] = useState<{[key: string]: boolean}>({});
    const setupDialog = (callBack: () => DialogConfigSetter) => dialogRef.current = callBack();

    // TODO: CONDITIONAL HOOKS
    // there is hooks below that are useing devie as a parameter
    // need to remove that condition and remain the same behaviour
    if (!device) return null;

    const editDevice = () => {
        if (readOnly) {
            const dialog = dialogRef.current;

            dialog?.({
                type: 'NOTIFICATION',
                title: 'Reed only device!',
                body: 'This device is read only',
            });
        } else {
            history.push(`/device/${deviceId}/edit`);
        }

    };

    const overlay = when(Boolean, () => <CheckEngineOverlay
        deviceId={device.id}
        dismiss={pipe(always(device.id), devicedb.dismissCheckEngine, () => setCEOverlay(false))}
        close={() => setCEOverlay(false)}
    />, CEOverlay);

    const tags = idValArr(itemTags || {});
    const isReadOnly = useIsReadOnly(deviceId, ItemType.device);

    const userCanDo = useSelector<iFullStoreState, Array<string>>(
        (s) => s.auth.user?.acl?.can
    );
    const canEditNotes = ACL.check(UserCan.EDIT_NOTES, userCanDo, true);
    const showTappedPeople =  ACL.check(UserCan.SEE_TAPPED_PEOPLE, userCanDo, true);
    const showTags = ACL.check(UserCan.SEE_TAGS, userCanDo, true);
    const mapRef = useSelector<iFullStoreState, React.RefObject<GoogleMap> | null>(state => state.gmap.mapRef());

    const lastLocation = useRedux((s) => s.devicesData.devicesLastPing.get(deviceId))?.coordinates?.location;
    const currentBounds = useRedux((s) => s.gmap.bounds);

    useEffect(() => {
        if(lastLocation?.lat < currentBounds.latSouth || lastLocation?.lat > currentBounds.latNorth ||
            lastLocation?.lng < currentBounds.lngWest || lastLocation?.lng > currentBounds.lngEast) {
            dispatch(MapActions.SET_BOUNDS(defaultBounds));
        }

    }, [lastLocation]);

    useEffect(() => {
        mapRef && mapRef.current && mapRef.current.context[MAP].setZoom(10);

        const getMenuPins = async () => {
            const fetchedMenuPinSettings = (await clientDb().child(`/people/people-details/${authUser.uid}/menuPin/${deviceId}`).once('value')).val();
            if (fetchedMenuPinSettings) setMenuPinSettings(fetchedMenuPinSettings);
        }

        getMenuPins();
    }, [])

    return <DashboardBlock overlay={overlay}>
        <ModalHeader
            title={device ? <div style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} >{device.name}</div> : <div />}
            action={{fa: faPencilAlt, title: 'Edit Device Information', click: () => editDevice(), isDisabled: isReadOnly}}
            role={isDriver}
        />
        <DeviceLastPingDetails device={device} setCEOverlay={setCEOverlay} />

        {
            !isDriver && (
                <LocalMinTile style={{padding: 4, paddingTop: 6}}>
                    <Row justify="flex-start" align="flex-end" style={{ gap: 10 }}>
                        <LocateDevice deviceId={device.id} />
                        <DeviceReportLink deviceId={device.id} />
                        <GoPremiumBtn deviceId={device.id} />
                    </Row>
                </LocalMinTile>
            )
        }

        {/*<div style={{ display: 'flex', flexWrap: 'wrap', minHeight: 'min-content' }}>*/}
        {/*    {pipe(*/}
        {/*        pathOr([], ['externalData', 'keyVals']),*/}
        {/*        mapObjIndexed((v, k) => <LocalMinTile key={k} title={k}>{v}</LocalMinTile>),*/}
        {/*        values*/}
        {/*    )(device)}*/}
        {/*</div>*/}

        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 'min-content' }}>

            <div style={{borderTop: '2px solid' + C.primaryText, marginTop: 4, marginBottom: 6}}/>

            <div className="col1" style={{width: '100%', minWidth: 200, flex: 1, display: 'flex', flexDirection: 'column'}}>
                {showTags && (<LocalTile
                    collapsible={true}
                    initialCollapsed={menuPinSettings[MenuPinType.TAGS] || false}
                    title="Tags"
                    action={
                        !isDriver && !readOnly && (
                            <TitleEditAction link={`/${ItemType.device}/${deviceId}/add/tags`}/>
                        )
                    }
                    deviceId={deviceId}
                    property={MenuPinType.TAGS}
                    isPinned={menuPinSettings[MenuPinType.TAGS] || false}
                >
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {!tags ? 'None Yet' : (tags as any).map(({val: tag}) => <StandardItem key={tag.details.id}
                                                                                              style={{margin: 4}}
                                                                                              itemId={tag.details.id}
                                                                                              displayName={tag.details.name}/>)}
                    </div>
                </LocalTile>
                )}
                <ExtraInfo
                    editUrl={`/${ItemType.device}/${deviceId}/add/extra-info`}
                    labels={currentLabels}
                    role={isDriver}
                    readOnly={isReadOnly}
                    itemId={device.id}
                    itemType={ItemType.device}
                    isPinned={menuPinSettings[MenuPinType.EXTRA_INFO] || false}
                    property={MenuPinType.EXTRA_INFO}
                />
                {(config?.strictAccess && countPerson > 1 && authUser.acl.can.includes(UserCan.DO_ANYTHING)) && (
                    <VisibleToPeople
                      deviceId={deviceId}
                      deviceTags={keys(itemTags)}
                      people={people}
                      isPinned={menuPinSettings[MenuPinType.VISIBLE_TO_PEOPLE] || false}
                    />
                )}
                {(countPerson > 1 && showTappedPeople) && (
                    <LocalTile
                      title="Assigned Driver"
                      action={null}
                      style={{textAlign: 'left'}}
                      collapsible={true}
                      initialCollapsed={menuPinSettings[MenuPinType.ASSIGNED_DRIVER] || false}
                      deviceId={deviceId}
                      property={MenuPinType.ASSIGNED_DRIVER}
                      isPinned={menuPinSettings[MenuPinType.ASSIGNED_DRIVER] || false}
                    >
                        {assignedPerson ?
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <span>{assignedPerson.displayName} test</span>
                                <span>{friendlyDiff(moment(), (device as any).assignedTo.time)}</span>
                                <button
                                    className="btn btn-primary btn-xs"
                                    onClick={(_) => devicedb.unAssignDevice(authUser)(deviceId, assignedPersonId)}
                                    disabled={!isDriverDevice && isDriver || isReadOnly}
                                >
                                    Tap Out
                                </button>
                            </div> :
                            isDriver ?
                                <button
                                    disabled={isReadOnly}
                                    className="btn btn-primary"
                                    onClick={(_) => devicedb.assignDevice(authUser)(deviceId, authUser.uid)}
                                >
                                    Tap in
                                </button> :
                                isReadOnly
                                    ?<p>You dont have permission to assing a driver</p>
                                    : <Link to={`/device/${deviceId}/assign`} className="btn btn-xs btn-primary">Assign to Person</Link>
                        }
                    </LocalTile>
                )}
            </div>

            {
                !isDriver && (
                    <>
                        <div className="col2"
                            style={{width: '100%', minWidth: 200, flex: 1, display: 'flex', flexDirection: 'column'}}
                        >
                            <AlertToggles
                                itemIdProps={deviceId}
                                itemTypeProps={ItemType.device}
                                style={{width: '100%'}}
                                itemId={device.id}
                                itemType={ItemType.device}
                                values={device.eventValues || {}}
                                eventKeys={device.deviceEvents}
                                deviceId={deviceId}
                                property={MenuPinType.ALERTS}
                                isPinned={menuPinSettings[MenuPinType.ALERTS] || false}
                            />
                        </div>
                        <CollapseTile
                            style={{margin: 5, marginLeft: 0, marginRight: 0}}
                            alwaysOpen={false}
                            disabled={false}
                            header={
                                <div style={{
                                    maxWidth: 280,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexWrap: 'wrap',
                                    overflow: 'hidden'}}
                                >
                                    <img src="images/engine.svg" alt='engine image' width={25} height={25} style={{marginRight: 8}} />
                                    Engine Code History
                                </div>
                            }
                            body={<EngineCodeTable deviceId={deviceId} />}
                        />
                        <div className="col3" style={{width: '100%', minWidth: 200, flex: 1, display: 'flex', flexDirection: 'column'}}>
                            <Notepad disabled={isReadOnly && !canEditNotes} notes={deviceNotes} deviceOrFence="device" deviceOrFenceId={deviceId} />
                        </div>
                    </>
                )
            }
        </div>
        <Dialog setupConfig={setupDialog} />
    </DashboardBlock>;
};

const DeviceLastPingDetails = ({device, setCEOverlay}: {device: iDeviceDetails; setCEOverlay: (p) => void}) => {
    const [lastDevicePingsFuel, setLastDevicePingsFuel] = useState<number | null>(null);

    const deviceLastPing = useSelector<iFullStoreState, iDevicePing | undefined | null>(
        (state) => state.devicesData.devicesLastPing.get(device.id),
        (l, r) => typeof l === typeof r && l?.pointId === r?.pointId,
    );
    const dialogRef = React.useRef<DialogConfigSetter>();
    const setupDialog = (callBack: () => DialogConfigSetter) => dialogRef.current = callBack();

    const availableDeviceLocation = deviceLastPing !== null;

    useEffect(() => {
        if (!availableDeviceLocation) {
            const dialog = dialogRef.current;

            dialog?.({
                type: 'NOTIFICATION',
                title: 'No device locations',
                body: 'This device has been activated and will begin to display location information once it is powered on.',
            });
        }
    }, [availableDeviceLocation]);

    useEffect(() => {
        const fetchData = async () => {
            const lastFuel = await devicedb.getDeviceLastFuel(deviceLastPing?.device);
            setLastDevicePingsFuel(lastFuel);
        };
    
        fetchData();
    }, [deviceLastPing])

    const hasFuel = lastDevicePingsFuel
    const hasEnergy = !!deviceLastPing?.bat_percent
    const hasVolt = !!deviceLastPing?.volt
    const { isCamera} = device;

    return <>
        {!deviceLastPing ? null :
            <Row wrap>
                <LocalMinTile nowrap style={{fontSize: 18, flex: 2}}>
                    <Row justify="space-between" full-height >
                        <FormatAddress address={deviceLastPing.address} />
                    </Row>
                </LocalMinTile>

                {deviceLastPing.speed &&
                    <LocalMinTile
                        style={{
                            whiteSpace: 'nowrap',
                            fontSize: 20,
                            lineHeight: '25px',
                            paddingBottom: 2,
                            paddingTop: 2,
                            flexGrow: 0,
                            flexShrink: 0,
                            textAlign: 'center',
                            overflowX: 'inherit'
                        }}
                    >
                        {friendlySpeed(deviceLastPing.speed, device.units)}
                    </LocalMinTile>
                }
            </Row>
        }
        <LocalMinTile style={{padding: 5}}>
                <Row justify="space-between" align="flex-start" wrap>
                {hasFuel && unless(not, always(
                    <Gauge
                        height={25}
                        width={25}
                        percent={lastDevicePingsFuel}
                        title="Fuel"/>),
                     deviceLastPing)}

                    {isCamera && <DevicesStatusNavigation device={device}/>}
                    { <DeviceStatuses
                        device={device}
                    /> }

                {hasEnergy && unless(not, always(
                    <Gauge
                        height={25}
                        width={25}
                        percent={deviceLastPing?.bat_percent}
                        title="Internal Battery" />),
                     deviceLastPing)}
                {hasVolt && when(safeHas('volt'), (p) => <VoltageIndicator volt={prop('volt', p)} />, deviceLastPing)}
                {unless(not, always(<BatteryIndicator percent={device.batteryPercent} />), 'batteryPercent' in device)}
                {unless(not, always(<CheckEngine onClick={() => setCEOverlay(true)} />), device.hasCheckEngine)}
            </Row>
        </LocalMinTile>

        <Dialog setupConfig={setupDialog} />
    </>;
};

const CheckEngine = ({onClick}) => (
    <Col
        align="center"
        onClick={onClick}
        style={{cursor: 'pointer', fontSize: 8, lineHeight: '9px', paddingRight: 3, zIndex: 3}}
        justify="flex-end"
    >
        <img src="images/engine.svg" alt='engine image' width={30} height={30}  />
        <span title="Click to dismiss">Check Engine</span>
    </Col>
);

const Gauge = ({percent, height, width, title}) => {

    const id = title.split(' ').join('_')

    return(
        <Col
        align="center"
        style={{zIndex: 1}}
        justify="flex-end"
    >
        <Row justify="center" align="center" style={{height, width}}>
            <div style={{width: 65, marginBottom: -10}}>
                <GaugeChart id={id}
                    arcWidth={0.2}
                    cornerRadius={1}
                    hideText
                    arcsLength={[0.1, 0.2, 0.7]}
                    colors={['#ee3e3e', '#fddc00', '#30b22d']}
                    percent={percent === 'N/A' ? 0 : percent / 100}
                />
            </div>
        </Row>
        <span style={{fontSize: 10}}>{title}: {percent === 'N/A' ? 'N/A' : `${percent}%`}</span>
    </Col>
    )
};

const VoltageIndicator = ({volt}) => (
    <Col
        align="center"
        style={{fontSize: 8, lineHeight: '9px', zIndex: 2}}
        justify="flex-end"
    >
        <span style={{fontSize: 23, letterSpacing: -1, lineHeight: '23px'}}>{volt}</span>
        <span>Volts</span>
    </Col>
);

const BatteryIndicator = ({percent}) => (
    <Col
        align="center"
        style={{fontSize: 8, lineHeight: '9px', zIndex: 2}}
        justify="flex-end"
    >
        <Fa
            title={`${percent}%`}
            icon={cond([
                [lte(75), always(faBatteryFull)],
                [lte(50), always(faBatteryThreeQuarters)],
                [lte(25), always(faBatteryHalf)],
                [lt(0), always(faBatteryQuarter)],
                [T, always(faBatteryEmpty)],
            ])(percent)}
            style={{color: 'orangered', fontSize: 30}}
        />
        <span>Battery {percent}%</span>
    </Col>
);

const TitleEditAction = ({link}) => <Link style={{color: '#fff'}} to={link}><Fa icon={faPencilAlt} /></Link>;

const localTileMStyle: css = {
    margin: 3,
    flex: 1,
};
const LocalMinTile = ({style = {} as React.CSSProperties, title='', nowrap=false as boolean, children}) => (
    <MinimalTile small={true} title={title} style={{...localTileMStyle, ...style, whiteSpace: nowrap ? 'nowrap' : 'normal'}}>
        {children}
    </MinimalTile>
);

const localTileStyle: css = {
    margin: 3,
    marginBottom: 6,
};
export const LocalTile = ({
    style = {} as React.CSSProperties,
    title= '',
    children,
    action,
    collapsible = false,
    initialCollapsed=false,
    loading=false,
    deviceId = undefined,
    property = undefined,
    isPinned = false}) => (
    <StandardTile
        style={{...style, ...localTileStyle}}
        title={title}
        action={action}
        collapsible={collapsible}
        initialCollapse={initialCollapsed}
        loading={loading}
        deviceId={deviceId}
        property={property}
        isPinned={isPinned}
    >
        {children}
    </StandardTile>
);

const GoPremiumBtn = ({deviceId}: {deviceId: string}) => {
    const [premiumRequested, setPremiumRequested] = useFirebase(`devices/device-details/${deviceId}/premiumRequested`);
    const [premiumCount, setPremiumCount] = useFirebase(`devices/premium-count/${deviceId}`)
    const [remainingPremiumCount] = devicedb.useMonthlyCounter('premium', deviceId);
    const premiumUntil = useSelector<iFullStoreState, moment.Moment | undefined>((s) => s.devicesData.devicesDetails.getIn([deviceId, 'premiumUntil']));
    const canPremium = useSelector<iFullStoreState, Boolean>((s) => s.devicesData.devicesDetails.getIn([deviceId, 'canPremium']))
    const isPremium = premiumUntil && premiumUntil.isAfter(moment());
    const [isShowCaptcha, setIsShowCaptcha] = useState(false)

    if(isPremium && premiumRequested && premiumCount){

        const [name, temp] = Object.entries(premiumCount)[0]
        const startOfMonth = moment().startOf('month')

        if(moment(temp.lastUpdated).isBefore(startOfMonth)){
            temp.remaining = temp.max
        }

        temp.lastUpdated = moment().format('YYYY-MM-DDThh:mm:ss')
        temp.remaining--

        setPremiumCount({[name]: temp})
        setPremiumRequested(false)
    }

    const dialogRef = React.useRef<DialogConfigSetter>();
    const setupDialog = (callBack: () => DialogConfigSetter) => dialogRef.current = callBack();

    const doPremiumRequest = async () => {
        if (isPremium) return;

        const dialog = dialogRef.current;

        if (!remainingPremiumCount) {
            dialog?.({
                type: 'NOTIFICATION',
                title: 'Premium unavailable',
                body: 'You have used all of your premium activations for the month',
            });

            return;
        }

        const confirmed = await dialog?.({
            type: 'CONFIRM',
            title: 'Activate Premium Service',
            body:'You are about to activate 24 hours of premium service.',
        });

        if (!confirmed) return;

        setIsShowCaptcha(true)

    };

    return(
        !!canPremium &&
        <>
            {isShowCaptcha&&<Captcha
                onVerify={() => setPremiumRequested(true)}
                closeCaptcha={() => setIsShowCaptcha(false)}/>}

            <span className="btn-link"
                onClick={doPremiumRequest}
                style={{textDecoration: 'none', cursor: !isPremium ? 'pointer' : 'default'}}>
                <Col align="center">
                    {premiumRequested && !isPremium ?
                        <Fa size={20} icon={faCircleNotch} spin title="Premium upgrade is being processed" /> :
                        <Fa
                            size={18}
                            icon={isPremium ? faToggleOn : faToggleOff}
                            style={{
                                fontSize: 16,
                                color: isPremium ? C.primaryColor : 'initial',
                                cursor: !isPremium ? 'pointer' : 'default'}}
                        />
                    }
                    <span style={{
                        whiteSpace: 'nowrap',
                        textDecoration: 'none',
                        lineHeight: '14px'}}>Theft Recovery</span>
                </Col>
            </span>
            <Dialog setupConfig={setupDialog} />
        </>
    );
};

const LocateDevice = React.memo(({deviceId}: {deviceId: string}) => {
    const [remainingLocateCount] = devicedb.useMonthlyCounter('location', deviceId);
    const [locationStatus, {locate: requestLocate, clear: clearLocate}] = useIsLocating(deviceId);
    const [, setFlash] = useReduxPath((s) => s.general.flashMessage, ['general', 'flashMessage']);

    const dialogRef = React.useRef<DialogConfigSetter>();
    const setupDialog = (callBack: () => DialogConfigSetter): void => {
        dialogRef.current = callBack();
    };

    useEffect(() => {
        if (locationStatus === LOCATION_STATUS.FAIL) {
            setFlash({
                message: 'Unable to locate your device. The map will automatically update once your device is able to report its location',
                fail: true,
            });
            clearLocate();
        } else if (locationStatus === LOCATION_STATUS.SUCCESS) {
            setFlash({
                message: 'Location Successful',
                success: true,
            });
            clearLocate();
        }
    }, [locationStatus]);

    const doLocate = async () => {
        if (locationStatus !== LOCATION_STATUS.INACTIVE) {
            return;
        }

        const dialog = dialogRef.current;

        // warn that no counts are available
        if (remainingLocateCount < 1) {
            dialog?.({
                type: 'NOTIFICATION',
                title: 'Exceeded Maximum Locates',
                body: 'Your account has exceeded the maximum locates for 24 hour period',
            });

            return;
        }

        const confirmLocation = await dialog?.({
            type: 'CONFIRM',
            title: 'Confirm Location Request',
            body: <div>
                <p>Your device automatically updates information as per your service plan. On Demand will attempt to locate your device regardless of report interval.</p>
                <p>This will deduct one On Demand Locate from your account.</p>
                <p>You have {remainingLocateCount} On Demand Locates left for this month.</p>
                <p>Click OK to locate.</p>
            </div>
        });

        if (!confirmLocation) return;

        requestLocate();
    };

    return <>
        <span className="btn-link" style={{textDecoration: 'none'}} onClick={doLocate}>
            <Col align="center">
                <Fa size={22} icon={faBullseye} />

                <span style={{whiteSpace: 'nowrap', textDecoration: 'none', lineHeight: '11px'}}>Locate</span>
            </Col>
        </span>
        <Dialog setupConfig={setupDialog} />
    </>;
});

const DeviceReportLink = React.memo(({deviceId}: {deviceId: string}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const goToReports = () => {
        dispatch(removeReportTrips());
        dispatch({ type: 'REPORT_RESET_ALL_FILTERS' });

        const nowDate = moment(new Date).set({'hour': 12, 'minute': 0, 'seconds': 0}).format('YYYY-MM-DD');
        const startTime = {
            hour: moment().startOf('day').format('h'),
            minute: moment().startOf('day').minute(),
            ampm: moment().startOf('day').format('a'),
        };
        const endTime = {
            hour:  moment().endOf('day').format('h'),
            minute: moment().endOf('day').minute(),
            ampm: moment().endOf('day').format('a'),
        };

        const startTimeParsed = moment(`${startTime.hour}:${startTime.minute} ${startTime.ampm}`, ["hh:mm A"]);
        const endTimeParsed = moment(`${endTime.hour}:${endTime.minute} ${endTime.ampm}`, ["hh:mm A"]);
        // each date has a start and end date that differ only in time
        const startDate = moment(nowDate).hour(startTimeParsed.hours()).minute(startTime.minute);
        const endDate = moment(nowDate).hour(endTimeParsed.hours()).minute(endTime.minute);
        
        dispatch(ReportAC.TOGGLE_ITEM_FILTER('device', deviceId));
        dispatch(ReportAC.UPSERT_DATE_GROUP_IN_DATES({ startDate, endDate }));

        history.push('/reports');
    }

    return (
        <>
        <span className="btn-link" style={{textDecoration: 'none'}} onClick={() => goToReports()}>
            <Col align="center">
                <img src={HistoryIcon} alt='History icon' width='30' height='30' style={{ marginBottom: 5 }} />

                <span style={{whiteSpace: 'nowrap', textDecoration: 'none', lineHeight: '11px'}}>Today’s Reports</span>
            </Col>
        </span>
        </>
    );
});


import React, {useEffect, useRef, useState} from 'react';
import styles from "./styles.module.scss";
import {Fa} from "../../../elements/fa";
import {faArrowLeft, faSpinner} from "@fortawesome/fontawesome-free-solid";
import {Row} from "../../../elements/flex";
import {getDvirByKey} from "../utils";
import DashboardBlock from "../../../DashboardBlock";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {dvirCloseDashboard, dvirOpenDashboard} from "../../../../stores/reducers/dashboardInfo/AC";
import {iDvirInfo, iDvirInspection, iFullStoreState, iHeaderItem} from "../../../../shared/interfaces";
import instance from "../../../../api/instance";
import axios from "axios";
import {localStorage} from "../../../../shared/storage";
import {ReactComponent as CheckIcon} from '../../../../assets/svg/check-line.svg';
import {ReactComponent as CloseIcon} from '../../../../assets/svg/close-line.svg';
import {ReactComponent as CameraIcon} from '../../../../assets/svg/camera.svg';

interface DvirFormProps extends RouteComponentProps<{ id: string }> {}

const mapStateToProps = (state: iFullStoreState, ownProps: DvirFormProps) => ({
  dvirId: ownProps.match.params.id,
});

const DvirHeaderStatus = ({status}) => {
  if (status === 1) return <CheckIcon style={{width: '40px', color: '#25991A', cursor: 'pointer'}}/>
  else if (status === 2) return <CloseIcon style={{width: '40px', color: '#B00808', cursor: 'pointer'}}/>

  return <span style={{fontWeight: 'bold'}}>N/A</span>
}

const DvirHeaderValuePicker = ({clickHandler, headerKey}) => {
  return (
    <div className={styles.HeadingValuePicker}>
      <div className={styles.HeadingValue} onClick={() => clickHandler(headerKey, 1)} style={{cursor: 'pointer'}}>
        <CheckIcon style={{width: '40px', color: '#25991A'}}/>
        <span>Pass</span>
      </div>
      <div className={styles.HeadingValue} onClick={() => clickHandler(headerKey, 2)} style={{cursor: 'pointer'}}>
        <CloseIcon style={{width: '40px', color: '#B00808'}}/>
        <span>Defect</span>
      </div>
    </div>
  )
}

const DvirUpdate = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ip, setIP] = useState<string>('');
  const [dvirInfo, setDvirInfo] = useState<iDvirInfo>();
  const [dvirHeaders, setDvirHeaders] = useState<iHeaderItem[]>();
  const [dvirInspections, setDvirInspections] = useState<iDvirInspection[]>();
  const [activeHeader, setActiveHeader] = useState<string | null>(null);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [activeHeaderCamera, setActiveHeaderCamera] = useState<string | null>(null);

  const timestampRef = useRef<null | number>(null);
  const cameraRef = useRef(null);

  if (timestampRef.current === null) {
    timestampRef.current = Date.now();
  }

  const [formData, setFormData] = useState({
    odometer: '',
    notes: '',
    header_items: [],
    images: []
  })

  const loginToken = localStorage.get('login-init-user');
  const user = loginToken.uid;

  const getIP = async () => {
    try {
      const {data} = await axios.get('https://api.ipify.org?format=json');

      setIP(data.ip);
    } catch (e) {
      console.log(e);
    }
  }

  const getDvir = async () => {
    try {
      const data = await getDvirByKey(props.dvirId);

      setDvirInfo(data.dvir);
      setDvirHeaders(data.header_items);
      setDvirInspections(data.inspections);

      setFormData({
        ...formData,
        header_items: data.header_items.map((header: iHeaderItem) => {
          return (
            {
              name: header.header,
              headerKey: header.firebase_key,
              headerStatus: 0
            }
          )
        })
      })
    } catch (e) {
      console.log(e);
    }
  }

  const updateFormDataHeader = (key: string, value: number) => {
    const newHeaders = formData.header_items.map((header) => {
      if (header.headerKey === key) {
        return {...header, headerStatus: value}
      }
      return header;
    })

    setFormData({
      ...formData,
      header_items: newHeaders
    });

    setActiveHeader(null);
  }

  const handleAddPhotoForHeading = (file, headingId) => {
    if (!file) return;

    const extension = file.name.split('.').pop();

    const newFilename = `${headingId}.${extension}`;

    const newFile = new File([file], newFilename, { type: file.type });

    setFormData((prev) => {
      const updatedImages = prev.images.filter(
        (img) => img.name.split('.')[0] !== headingId
      );

      return { ...prev, images: [...updatedImages, newFile] };
    });
  }

  const handleNotesChange = (e) => {
    setFormData({
      ...formData,
      notes: e.target.value
    });
  }

  const handleOdometerChange = (e) => {
    setFormData({
      ...formData,
      odometer: e.target.value
    });
  }

  const updateDvir = async (e) => {
    e.preventDefault();

    const headers = formData.header_items.map(header => {
      return {
        'firebase_key': header.headerKey,
        'status': header.headerStatus
      }
    });

    const URL = 'api/dvir/update-dvir';

    try {
      const payload = new FormData();

      payload.append('organization_key', localStorage.get('active-client'));
      payload.append('dvir_report_key', props.dvirId);
      payload.append('start_date', Math.round(timestampRef.current / 1000).toString());
      payload.append('end_date', Math.round(Date.now() / 1000).toString());
      payload.append('person_key', dvirInfo.person_key);
      payload.append('browser_type', window.navigator.userAgent);
      payload.append('ip_address', ip);
      payload.append('odometer', formData.odometer);
      payload.append('notes', formData.notes);
      payload.append('status', dvirInfo.status);
      payload.append('prefix', dvirInfo.status.split('-')[0]);
      payload.append('header_items', JSON.stringify(headers));

      formData.images.forEach((image) => {
        payload.append("images[]", image);
      });

      setIsLoading(true);

      const res = await instance.post(URL, payload);

      setIsLoading(false);

      history.goBack();
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    dispatch(dvirOpenDashboard());

    getIP();
    getDvir();

    return () => {
      dispatch(dvirCloseDashboard());
    }
  }, []);

  return (
    <DashboardBlock>
      <div className={styles.DvirItemHeader}>
        <span
          className="no-print btn-link"
          style={{paddingLeft: 10, marginRight: 20}}
          onClick={() => history.goBack()}
        >
          <Fa icon={faArrowLeft}/>
        </span>
      </div>
      {isLoading && (
        <Row justify='center' align='center'>
          <Fa icon={faSpinner} spin style={{ fontSize: 30 }}/>
        </Row>
      )}
      {!isLoading && formData.header_items && (<div className={styles.UpdateDvirContainer}>
        <table className={styles.DvirHeadingsTable}>
          <thead>
            <tr>
              <th>Header</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
          {formData.header_items.map(header => {
            return (
              <tr style={{position: 'relative'}}>
                <td>{header.name}</td>
                <td className={header.headerStatus === 2 ? styles.HeadingValue : ''}>
                  <div onClick={() => {
                    setActiveHeader(header.headerKey)
                  }}>
                    <DvirHeaderStatus status={header.headerStatus}/>
                  </div>
                  {header.headerStatus === 2 && (
                    <CameraIcon
                      style={{width: '35px'}}
                      onClick={(e) => {
                        setActiveHeaderCamera(header.headerKey);
                        e.stopPropagation();
                        if (cameraRef.current) {
                          cameraRef.current.click();
                        }
                      }}
                    />)}
                </td>
                {activeHeader === header.headerKey && <DvirHeaderValuePicker
                    clickHandler={updateFormDataHeader}
                    headerKey={header.headerKey}
                />}
              </tr>
            )
          })}
          <input
            type='file'
            accept='image/*'
            style={{display: 'none'}}
            ref={cameraRef}
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                handleAddPhotoForHeading(file, activeHeaderCamera)
              }
            }}
          />
          </tbody>
        </table>
        <form className={styles.UpdateDvirForm} onSubmit={updateDvir}>
          <input
            type='number'
            value={formData.odometer ?? ''}
            className={styles.UpdateDvirOdometer}
            onChange={handleOdometerChange}
            min={0}
            placeholder='Odometer Reading'
            required={true}
          />
          <textarea
            className={styles.UpdateDvirNotes}
            value={formData.notes}
            onChange={handleNotesChange}
            placeholder='Driver Notes'
          />
          <div>
            <input
              type='checkbox'
              id='dvirVerification'
              checked={isVerified}
              onChange={() => setIsVerified(!isVerified)}
              required={true}
            />
            <label htmlFor='dvirVerification' style={{fontWeight: 'bold'}}>{`I ${loginToken.displayName} certify that this DVIR is a correct`}</label>
          </div>
          <button
            type='submit'
            className={styles.UpdateDvirBtn}
          >
            Save
          </button>
        </form>
      </div>)}
    </DashboardBlock>
  )
}

export default connect(mapStateToProps)(DvirUpdate);

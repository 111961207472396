import React, {useEffect, useState} from 'react';
import DashboardBlock from "../../../DashboardBlock";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {HttpStatus, iDvirForm, iDvirFormsItem, iFullStoreState} from "../../../../shared/interfaces";
import styles from "./styles.module.scss";
import {Fa} from "../../../elements/fa";
import {faArrowLeft} from "@fortawesome/fontawesome-free-solid";
import {dvirCloseDashboard, dvirOpenDashboard} from "../../../../stores/reducers/dashboardInfo/AC";
import {DevicesDetailsContainer} from "../../../../stores/reducers/devicesData";
import {is} from "immutable";
import {localStorage} from "../../../../shared/storage";
import {useRedux} from "../../../../states/redux-state";
import {fetchDvirFormsByPage} from "../utils";
import {DvirPagination} from "../DvirPagination";
import instance from "../../../../api/instance";
import {toast} from 'react-toastify';

const CreateDvir = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedDevice, setSelectedDevice] = useState<string>('');
  const [selectedForm, setSelectedForm] = useState<string>('');
  const clientId = localStorage.get('active-client');
  const uid = useRedux((s) => s.auth.user.uid);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [forms, setForms] = useState<iDvirFormsItem[]>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [errorsShouldDisplay, setErrorsShouldDisplay] = useState<boolean>(false);

  const devices = useSelector<iFullStoreState, DevicesDetailsContainer>(
    (s) => s.devicesData.devicesDetails,
    (l, r) => is(l, r)
  );
  const devicesArray = devices.valueSeq().toArray();

  const handleDeviceChange = (event) => {
    setErrorsShouldDisplay(false);
    setSelectedDevice(event.target.value);
  }

  const handleFormChange = (event) => {
    setErrorsShouldDisplay(false);
    setSelectedForm(event.target.value);
  }

  const fetchDvirForms = async (page: number = 1, limit: number = 100) => {
    try {
      setIsLoading(true);

      const data = await fetchDvirFormsByPage({page, limit, clientId});

      const {data: forms, total_pages: totalPages} = data;

      setTotalPages(totalPages);
      setForms(forms);

      setIsLoading(false);
    } catch ({message}) {
      console.log(message)
    }
  }

  const createDvir = async () => {
    const URL = 'api/dvir/create-dvir';
    const body = {
      organization_key: clientId,
      device_key: selectedDevice,
      dvir_form_key: selectedForm,
      person_key: uid,
      created_at: Math.round(new Date().getTime() / 1000)
    }
    const errors = [];

    let hasErrors = false;

    if (selectedDevice === '') {
      errors.push('Device can\'t be empty.');
      hasErrors = true;
    }

    if (selectedForm === '') {
      errors.push('Form can\'t be empty.');
      hasErrors = true;
    }

    if (!hasErrors) {
      try {
        const res = await instance.post(URL, {...body});

        if (res.data.status === 'unclosed-dvir') {
          toast.error('Unable to create a new DVIR inspection. All existing inspections must have a status of \'Completed\' or \'Needs Maintenance.\' Please close any open inspections before proceeding.');
        }
        else if (res.status === HttpStatus.OK) {
          setErrorsList([]);
          history.goBack();
        }
      }
      catch (e) {
        console.log(e);
        errors.push('Error while creating a DVIR, please try again later or select a different device.')
      }
    }

    if (errors.length) {
      setErrorsList(errors);
      setErrorsShouldDisplay(true);
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);

    fetchDvirForms(page);
  }

  useEffect(() => {
    dispatch(dvirOpenDashboard());

    fetchDvirForms();

    return () => {
      dispatch(dvirCloseDashboard());
    }
  }, []);

  return (
    <DashboardBlock>
      <div className={styles.CreateDvirHeader}>
        <span className="no-print btn-link" style={{paddingLeft: 10, marginRight: 20}} onClick={() => history.goBack()}><Fa
          icon={faArrowLeft}/></span>
        <h2>Create DVIR</h2>
        <div></div>
      </div>
      <div className={styles.CreateDvirContainer}>
        <p>Device</p>
        <select
          value={selectedDevice}
          onChange={handleDeviceChange}
        >
          <option>Search for a device ...</option>
          {devicesArray.map(device => (
            <option key={device.id} value={device.id}>
              {device.name}
            </option>
          ))}
        </select>
        {forms && forms.length > 0 && !isLoading && <div className={styles.CreateDvirSelectFromContainer}>
          <p>Form</p>
          <select
            value={selectedForm}
            onChange={handleFormChange}
          >
            <option>Search for a form ...</option>
            {forms.map(form => (
              <option key={form.firebase_key} value={form.firebase_key}>
                {form.name}
              </option>
            ))}
          </select>
          {totalPages > 1 && <DvirPagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />}
        </div>}
        <button className={styles.CreateDvirBtn} onClick={createDvir}>Create</button>
        {errorsShouldDisplay && <div className={styles.CreateDvirErrors}>
          {errorsList.map((error, index) => (
            <span key={index}>{error}</span>
          ))}
          <button className={styles.CloseBtn} onClick={() => setErrorsShouldDisplay(false)}>&#x2715;</button>
        </div>}
      </div>
    </DashboardBlock>
  )
};

export default CreateDvir;

import instance from "../../../api/instance";

interface iFetchDvirFormsByPageParams {
  page?: number;
  limit?: number;
  clientId: string;
}

export const fetchDvirFormById = async (id: string) => {
  const URL = `api/dvir-form/get-dvir-form?dvir_form_key=${id}`;

  const {data} = await instance.get(URL);

  return data;
}

export const fetchDvirFormsByPage = async (params: iFetchDvirFormsByPageParams) => {
  const {page = 1, limit = 10, clientId} = params;

  const URL = 'api/dvir-form/get-dvir-forms';
  const body = {
    page,
    limit,
    organization_key: clientId
  }

  const {data} = await instance.post(URL, {...body});

  return data;
}

export const getDvirByKey = async (key: string) => {
  const URL = `api/dvir/get-dvir?dvir_report_key=${key}`;

  const {data} = await instance.get(URL);

  return data.data;
}

export const dvirStatusMapping = {
  'completed': 'Completed',
  'maintenance-inspection': 'Needs maintenance',
  'pre-trip-inspection': 'Needs pre-trip inspection',
  'post-trip-inspection': 'Needs post-trip inspection'
}

export const dvirInspectionMapping = {
  'pre-trip-inspection': 'Pre-trip inspection',
  'post-trip-inspection': 'Post-trip inspection',
  'maintenance-inspection': 'Maintenance inspection'
}

export const formatDate = (inputDate) => {
  try {
    const date = new Date(inputDate);

    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    });
  }
  catch (e) {
    console.log(e);
  }

  return '';
}

export const fetchDefectPhoto = async (id: number, status: string) => {
  const URL = `api/dvir/get-item-files?header_item_id=${id}&status=${status}`;

  try {
    const {data} = await instance.get(URL);

    return data[0]['file_url'];
  }
  catch (e) {
    console.log(e);
  }
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { faPencilAlt } from '@fortawesome/fontawesome-free-solid';

import { mapStateToProps } from './PersonEditPage.types';
import DashboardBlock from '../../DashboardBlock';
import ModalActions from '../../menus/modal/modal-actions';
import ModalHeader from '../../menus/modal/modal-header';
import { noSubmit } from '../../../shared/helpers';
import C from '../../../shared/constants';
import { Fa } from '../../elements/fa';
import Dialog, { DialogConfigSetter } from '../../Dialog';
import MinimalTile from '../../general/MinimalTile';
import {history, store} from '../../../stores/store';
import * as peopleDb from '../../../shared/db/people-db';
import {isValidEmail, isValidPhoneNumber } from '../../../utils';

interface IPersonEdits {
  displayName?: string;
  mainNumber?: string;
  email?: string;
  photoURL?: string;
}

const LocalTile = ({ title, children }) => (
  <MinimalTile title={title} small style={{ marginBottom: 10 }}>
    {children}
  </MinimalTile>
);

const PersonEditPageTest = (props) => {
  const { person } = props;
  const [personEdits, setPersonEdits] = useState<IPersonEdits>({});

  const cancel = () => {
    setPersonEdits({});
    history.goBack();
  };

  const update = async () => {
    const { id: personId } = props.match.params;
    const { authUser } = props;

    if (authUser) {
      if (
        (isValidEmail(personEdits.email) || !personEdits.email) &&
        (isValidPhoneNumber(personEdits.mainNumber) || !personEdits.mainNumber)
      ) {
        await peopleDb.updatePersonDetails(authUser)(personId, personEdits);
        setPersonEdits({});
        history.goBack();
      } else {
        const body = (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <p>Please enter a valid 10 digit phone number with no additional symbols. <b>eg. 5551235555</b></p>
            <p>OR</p>
            <p>Enter a valid Email address. <b>eg. myname@example.com</b></p>
          </div>
        );

        await dialog?.({
          title: 'Incorrect input!',
          body,
          type: 'NOTIFICATION',
        });
      }
    } else {
      setPersonEdits({});
      history.goBack();
    }
  };

  const saveImage = async ({ target }) => {
    const { id: personId } = props.match.params;
    const url = await peopleDb.storeProfileImage(personId, target.files[0]);
    setPersonEdits({ ...personEdits, photoURL: url });
  };

  const editDisplayName = (e) => {
    setPersonEdits({ ...personEdits, displayName: e.target.value });
  };

  const editPhoneNumber = (e) => {
    setPersonEdits({ ...personEdits, mainNumber: e.target.value });
  };

  const editEmail = (e) => {
    setPersonEdits({ ...personEdits, email: e.target.value });
  };

  const dialogRef = React.useRef<DialogConfigSetter>();
  const dialog = dialogRef.current;
  const setupDialog = (callBack: () => DialogConfigSetter) =>
    (dialogRef.current = callBack());

  useEffect(() => {
    const newPerson: IPersonEdits = {
      displayName: person.displayName || '',
      mainNumber: person.mainNumber || '',
      email: person.email || '',
    };
    setPersonEdits(newPerson);
  }, []);

  if (!person) return null;

  const { photoURL = person.photoURL } = personEdits;

  return (
    <DashboardBlock
      overlay={
        Object.keys(personEdits).length ? (
          <ModalActions
            buttons={[
              { click: cancel, title: 'Cancel' },
              { click: update, title: 'Update', primary: true },
            ]}
          />
        ) : undefined
      }
    >
      <ModalHeader title="Edit Person" />
      <form onSubmit={noSubmit}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <span
            className="btn-link"
            onClick={(_) => {
              document.getElementById('user-file-upload')?.click?.();
            }}
          >
            <div
              style={{
                width: 90,
                height: 90,
                borderRadius: 90,
                backgroundImage: `url(${photoURL})`,
                backgroundSize: 'cover',
                border: `2px solid ${C.mediumGray}`,
                position: 'relative',
              }}
            >
              <Fa
                icon={faPencilAlt}
                style={{
                  position: 'absolute',
                  right: -7,
                  bottom: -2,
                  fontSize: 12,
                  color: C.primaryColor,
                }}
              />
            </div>
          </span>
        </div>
        <div>
          <input
            type="file"
            id="user-file-upload"
            name="image"
            onChange={saveImage}
            style={{ display: 'none' }}
          />
        </div>
        <LocalTile title="Display Name">
          <input
            key="title-input"
            name="displayName"
            className="mas-input"
            style={{ ...C.inputCss, width: '100%' }}
            onChange={editDisplayName}
            value={personEdits.displayName}
          />
        </LocalTile>
        <LocalTile title="Main Phone">
          <input
            key="title-input"
            name="mainNumber"
            className="mas-input"
            style={{ ...C.inputCss, width: '100%' }}
            onChange={editPhoneNumber}
            value={personEdits.mainNumber}
          />
        </LocalTile>
        <LocalTile title="Email">
          <input
            key="title-input"
            name="email"
            className="mas-input"
            style={{ ...C.inputCss, width: '100%' }}
            onChange={editEmail}
            value={personEdits.email}
          />
        </LocalTile>
      </form>
      <Dialog setupConfig={setupDialog} />
    </DashboardBlock>
  );
};

export default connect(mapStateToProps)(PersonEditPageTest);
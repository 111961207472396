import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';

import {
    alertDotIcon,
    friendlySpeed,
    isDev,
    countDateWithUserAndTimezoneOffset,
    clearTimezone,
} from '../../shared/helpers';
import {HttpStatus, iDeviceDetails, iDevicePing, iFullStoreState, iPerson, Units} from '../../shared/interfaces';
import { Point } from './oval-polygon';
import { Col } from '../elements/flex';
import { Fa } from '../elements/fa';
import { useRedux } from '../../states/redux-state';
import {IS_MOBILE_APP, UserCan} from '../../shared/constants';
import { clientStore, FieldValue } from '../../shared/firebase';
import {checkStatusCamera, dayChooseByTimestamp, getAvailableTime, getDownloadPoint} from '../../api/openApi';
import { IReducerSelectedPoint } from '../../stores/reducers/selectedPoint';
import { hidePoint, showPoint } from '../../stores/reducers/devicesTripsPoints/AC';
import {getTimezoneByValue} from '../pages/devices/DeviceTabPageEdit/Timezones/Timezones.helper';
import { cameraAvailableTime, openModal, statusCameraOnline, takeDeviceInfo } from '../../stores/reducers/videoCameras';
import { iReducersState } from '../../stores/reducers';
import { getDownloadAlert } from '../../api/openApi';
import AddAlarmIdModalPrompt from './AddAlarmIdModalPrompt/AddAlarmIdModalPrompt';
import AddAlarmIdModalForm from './AddAlarmIdModalForm/AddAlarmIdModalForm';

import styles from './PointDetails.module.scss';
import { faSpinner } from '@fortawesome/fontawesome-free-solid';

export const shouldShowPoint = ({showPOI, showDots}) => (point: iDevicePing) => {
    const ico = showPOI ? alertDotIcon(point.alertActivity) : false;
    // @ts-ignore
    const isSpeed = point.alertActivity.hasspeedingcapped || point.alertActivity.hasspeedingposted;
    const isHidden = point.hidden;

    if (!showPOI && isHidden && (ico || isSpeed)) return false;
    return !(!showDots && !ico && !isSpeed);
}

enum DownloadPointResponseStatuses {
    ADD_POINT = 'Add_point',
    UNKNOWN = 'Unknown',
    DOWNLOADING = 'Downloading',
    WAITING = 'Waiting',
    FAILURE = 'Failure',
    COMPLETED = 'Completed'
}

type IPoisProps = {
  lastPointId?: string;
  tripIdx: number;
  points: Array<iDevicePing>;
  color: string;
  units: Units;
  tripId: string;
};

type VideoTypeName = 'Event' | 'Point';

enum VideoTypeNameEnum {
    EVENT = 'Event',
    POINT = 'Point'
}

export const Pois = (props: IPoisProps) => {
    const { lastPointId, points, color, units, tripIdx, tripId } = props;
    const user = useRedux(s => s.auth.user.acl);
    const showPOI = useRedux(s => s.gmap.showPOI);
    const showDots = useRedux(s => s.gmap.showDots);
    let filteredPois = points;
    if (!showDots || !showPOI) {
        filteredPois = points.filter(shouldShowPoint({showDots, showPOI}));
    }
    if (showDots && !user.can.includes(UserCan.HIDE_POINTS)) {
        filteredPois = points.filter(point => {
            return !point.hidden;

        })
    }

    const checkedPoints = tripId === "pointsWithoutTripId" ? points : filteredPois;

    return <>{checkedPoints.map((point, idx) => {
        return <Point
                key={point.pointId}
                point={point}
                tripIdx={tripIdx}
                color={color}
                isLastPoint={lastPointId === point.pointId}
                units={units}
                idx={idx+1}
                icoSizeDiff={22} // sos slightly bigger cause of text
            />
        }
    )}</>;
}

type iPointDetails = {gotoStreet: () => any, point: iDevicePing, units: Units, timezone?: string};

export const PointDetails = ({gotoStreet, point, units, timezone = 'EST'}: iPointDetails) => {
    const { address = false, posted_speed, time, msg, alertActivity, device, alarmId } = point;
    const [loading, setLoading] = useState(false);
    const [isDownloadingInProcess, setIsDownloadingInProcess] = useState(false);
    const [isDownloadStarted, setIsDownloadStarted] = useState(false);
    const [isFailureWhileDownloadingPoint, setIsFailureWhileDownloadingPoint] = useState(false);
    const [isFailureWhileDownloadingEvent, setIsFailureWhileDownloadingEvent] = useState(false);
    const [isFailure, setIsFailure] = useState(false);
    const [failurePointId, setFailurePointId] = useState();
    const [isAddAlarmIdPromptModalOpen, setIsAddAlarmIdPromptModalOpen] = useState(false);
    const [isAddAlarmIdFormModalOpen, setIsAddAlarmIdFormModalOpen] = useState(false);
    const [IsUserHasNotEnteredAllData, setIsUserHasNotEnteredAllData] = useState(false);

    const [formData, setFormData] = useState({ alarmId: alarmId, pointId: point.pointId, deviceId: point.device, time: point.time, channel: "", file_type: "1", stream_type: "2", before: "", after: "" });
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedPoint = useSelector<iFullStoreState, IReducerSelectedPoint>(state => state.selectedPoint);
    const [isButtonHidden, setIsButtonHidden] = useState(point.hidden)

    const deviceData = useSelector<iFullStoreState, iDeviceDetails>(
        (state) => state.devicesData.devicesDetails.get(device)
    );
    const { isCamera, id: deviceId, 'extra-info': serialNumber }:any = deviceData;
    const serial = Object.values(serialNumber)[0].toString();
    const isEvent = !!Object.keys(point.alertActivity).length

    const auth = useRedux(s => s.auth);
    const { user: { acl: user }, isDriver } = auth;

    const driverId = deviceData.assignedTo?.personId;
    const assignedDriver = useSelector<iFullStoreState, iPerson>(state => driverId ? state.general.people[driverId] : null);

    const checkingStatus = async () => {
        setLoading(true)
        const {onlineStatus, channels, cameraTime} = await checkStatusCamera({firebaseDeviceId: deviceData.id});

        dispatch(statusCameraOnline(onlineStatus));
        dispatch(cameraAvailableTime(cameraTime?.available_time));
        setLoading(false);
    }

    const { cameraOnline, deviceInfo, availableTime } = useSelector((state: iReducersState) => state.videoCameras);
    const CAMERA_ONLINE = cameraOnline == 1;
    const cameraStatus = CAMERA_ONLINE ? 'Camera is online' : 'Camera is offline';

    useEffect(() => {
        setIsButtonHidden(point.hidden);
        const failurePoints = JSON.parse(sessionStorage.getItem('failurePoints')) || [];
        const isCurrentPointFailure = failurePoints.includes(point.pointId);

        if(!isEvent) {
            setFormData({
                ...formData,
                stream_type: '0'
            });
        }

        if (failurePointId && failurePointId !== point.pointId) {
            setIsFailure(false);
        }
        else if (isCurrentPointFailure) {
            setIsFailure(true);
        }

        setIsFailureWhileDownloadingEvent(false);
        setIsFailureWhileDownloadingPoint(false);
    }, [point])

    const hidePointHandle = async () => {
        setIsButtonHidden(true)
        const points = clientStore().collection('points').doc(point.pointId);
        await points.update({'hidden': true});

        await dispatch(hidePoint(selectedPoint.deviceId, point.tripId, point.pointId));
    }

    const showPointHandle = async () => {
        setIsButtonHidden(false)
        const points = clientStore().collection('points').doc(point.pointId);
        await points.update({'hidden': FieldValue.delete()});

        await dispatch(showPoint(selectedPoint.deviceId, point.tripId, point.pointId));
    }
    const showAlertTitle = Object.keys(alertActivity).length > 0

    const timezoneData = getTimezoneByValue(timezone);
    const clearedTimezone = clearTimezone(timezone);
    const [timezoneTime, ...countries] = timezoneData.split(' ');
    const offSetTime = countDateWithUserAndTimezoneOffset(time, clearedTimezone)
    const alertDate = moment(time).format('YYYY-MM-DD'); // offSetTime.format("HH:mm:ss");
    const alertTime = moment(time).format("HH:mm:ss"); // offSetTime.format('YYYY-MM-DD');
    const checkingDateAndSetAvailableVideo = async () => {
        setLoading(true);
        const data = await dayChooseByTimestamp({ firebaseDeviceId: deviceId, convertDate: alertDate, alertTime });
        if(data?.length) {
            dispatch(takeDeviceInfo({id: deviceId, alertDate, alertTime}));
            dispatch(openModal({key: "openHistoryModal", value: true}));
        }
        setLoading(false);
        return data
    }

    const check30DaysLeft  = isMoreThan30DaysAgo(new Date(offSetTime.format('YYYY-MM-DD')));

    useEffect(() => {
        if(isCamera && !check30DaysLeft) {
            checkingStatus();
        }
    }, [alertDate, point])

    function isMoreThan30DaysAgo(date) {
        //                   days  hours min  sec  ms
        const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
        const timestampThirtyDaysAgo = new Date().getTime() - thirtyDaysInMs;

        return timestampThirtyDaysAgo > date ? true : false;
    }

    const showAlertHistoryVideo = () => {
        if (CAMERA_ONLINE && availableTime > 0) {
            checkingDateAndSetAvailableVideo();
        }
        if(CAMERA_ONLINE && availableTime === 0) {
            dispatch(openModal({key: "openBuyMoreModal", value: true}));
        }
    }

    const handleLink = (link) => {
        window['cordova'].InAppBrowser.open(link, '_system', 'location=yes');
    }

    const downloadVideoFromUrl = async (url: string, name: VideoTypeName, channel: number | string = 1) => {
        try {
            const response = await axios({
                url,
                method: 'GET',
                responseType: 'blob'
            })

            if (response.status === HttpStatus.OK) {
                const urlObject = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = urlObject;
                link.setAttribute('download', `${name}_${point.pointId ?? ''}_${offSetTime.format('MM.DD.YY h mm A') ?? ''}_${channel}.mp4`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (e) {
            console.log(e);

            if (name === VideoTypeNameEnum.EVENT) {
                setIsFailureWhileDownloadingEvent(true);
            }
            else {
                setIsFailureWhileDownloadingPoint(true);
            }
        }
    }

    const downloadAlert = async (alarmId) => {
        const downloadedAlert = await getDownloadAlert(alarmId);

        if (downloadedAlert.success && downloadedAlert.link) {
            await downloadVideoFromUrl(downloadedAlert.link, 'Event');
        }
        if (downloadedAlert.downloading_in_process) {
            setIsDownloadingInProcess(true);
        }
        if (downloadedAlert.add_alarm) {
            setIsAddAlarmIdPromptModalOpen(true);
        }
        if (downloadedAlert.update_alarm) {
            // filtering alarmTask object to get alarmTask object without falsy value ""
            const asArray = Object.entries(downloadedAlert.alarmTask);
            const filtered = asArray.filter(([key, value]) => value !== "");
            const alarmTaskObjWithoutFalsyValue = Object.fromEntries(filtered);

            setFormData({...formData, ...alarmTaskObjWithoutFalsyValue })
            setIsUserHasNotEnteredAllData(true);
            setIsAddAlarmIdPromptModalOpen(true);
        }
    }

    const downloadPoint = async (pointId) => {
        const downloadedPoint = await getDownloadPoint(pointId);

        if (downloadedPoint.status === DownloadPointResponseStatuses.COMPLETED) {
            setIsDownloadStarted(true);

            for (const res of downloadedPoint.data) {
                await downloadVideoFromUrl(res.bucket_url, 'Point', res.channel);
            }

            setIsDownloadStarted(false);
        }
        else if (downloadedPoint.status === DownloadPointResponseStatuses.WAITING || downloadedPoint.status === DownloadPointResponseStatuses.DOWNLOADING){
            setIsDownloadingInProcess(true);
        }
        else if (downloadedPoint.status === DownloadPointResponseStatuses.ADD_POINT || downloadedPoint.status === DownloadPointResponseStatuses.UNKNOWN){
            setIsAddAlarmIdPromptModalOpen(true);
        }
        else if (downloadedPoint.status === DownloadPointResponseStatuses.FAILURE){
            setIsFailure(true);
            setFailurePointId(pointId);
            const failurePoints = new Set(JSON.parse(sessionStorage.getItem('failurePoints')) || []);
            failurePoints.add(pointId);
            sessionStorage.setItem('failurePoints', JSON.stringify([...failurePoints]));
        }
    }

    const generateDirections = (address) => {
        if (!address) return null;
        if (IS_MOBILE_APP) {
            return (
                <button
                    type='button'
                    className="btn-link"
                    onClick={() => handleLink(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(`${address.street},${address.city}`)}`)}
                >
                    Directions
                </button>
            );
        } else {
            return (
                <a className="btn-link"
                   href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(`${address.street},${address.city}`)}`}
                   target='_blank'
                   rel='noopener noreferrer'
                >Directions</a>
            );
        }
    }

    return (
        <div className={styles.pointDetails}>
            {showAlertTitle && <div className={styles.alertTitle}>{msg === 'Front collision' ? 'Potential Front collision' : msg}</div>}
            <div>
                <p className={styles.deviceTime}>{offSetTime.format('MM/DD/YY h:mm A')}</p>
                <div className={styles.timezoneTime}>
                    <span className={styles.timezoneTimeTitle}>{timezoneTime}</span>
                    <span className={styles.timezoneTimeInfo}>{countries}</span></div>
            </div>

            {/*{!isDev ? null : <div className={styles.devInfo}>*/}
            {/*    {(point as iDevicePing).pointId && isDev ? <p className={styles.devInfoKey}>key: {(point as iDevicePing).pointId}</p> : null}*/}
            {/*    {(point as iDevicePing).tripId && isDev ? <p>trip: {(point as iDevicePing).tripId}</p> : null}*/}
            {/*    <div>{point.coordinates.location.lat}, {point.coordinates.location.lng}</div>*/}
            {/*</div>}*/}

            <div className={styles.speedInfo}>
                <p>
                    <span className={styles.speed}>Speed:</span>
                    {friendlySpeed(point.speed, units)}
                </p>
            </div>

            {posted_speed ? <div>Posted Speed: {posted_speed}</div> : null}

            <div className={styles.locationInfo}>
                <span className={styles.location}>Current location:</span>
                <div>
                    {!address ? null : <span>{address.street}</span>}
                    {!address ? null :<p>{address.city}, {address.state} {address.zip}</p>}
                </div>
            </div>

            {assignedDriver && <div className={styles.assignedDriverInfo}>
                <span className={styles.driverTitle}>Driver:</span>
                <div>
                    <span>{assignedDriver.displayName}</span>
                </div>
            </div>}

            {!isDriver && (
                <>
                    <Col className={styles.driverInfo} /* todo: Use default flex classes in refactor version */ >
                        {/* <img src={`https://maps.googleapis.com/maps/api/streetview?size=325x175&location=${lat},${lng}&fov=90&heading=235&pitch=10&key=AIzaSyBsNvkRiUmkDgv71-TaWfubQnf5E1niYXY`} /> */}
                        <span className="btn-link" onClick={gotoStreet}>Go to Street</span>
                        <span className="btn-link"
                              onClick={() => history.push(`/device/${deviceId}`)}>Go to Device</span>
                        {assignedDriver && <span className="btn-link"
                               onClick={() => history.push(`/person/${assignedDriver.id}`)}>Go to Driver</span>}
                        {generateDirections(address)}
                        {(isCamera && !check30DaysLeft) &&
                            <Tooltip title={cameraStatus} placement='top'>
                                <button className={styles.playVideo} onClick={() => showAlertHistoryVideo()}>
                                    {loading ? <Fa icon={faSpinner} spin/> : 'Play Video'}
                                </button>
                            </Tooltip>
                        }
                        {(isEvent && isCamera && !check30DaysLeft && alarmId) &&
                            <Tooltip title={'Download event'} placement='top'>
                                {isDownloadingInProcess
                                    ? (<div className={styles.isDownloadingInProcess}>Download started. Check back soon.</div>)
                                    : isFailureWhileDownloadingEvent ? (<div className={styles.isDownloadingInProcess}>An error occurred while downloading</div>)
                                    : (
                                        <button
                                            className={styles.downloadEvent}
                                            onClick={() => downloadAlert(alarmId)}
                                        >
                                            Download event
                                        </button>
                                    )}
                            </Tooltip>
                        }
                        {(!isEvent && isCamera && !check30DaysLeft) &&
                          <Tooltip title={'Download video'} placement='top'>
                              {isDownloadingInProcess
                                ? (<div className={styles.isDownloadingInProcess}>Downloading in process</div>)
                                : isDownloadStarted ? (<div className={styles.isDownloadingInProcess}>The download will begin shortly</div>)
                                : isFailureWhileDownloadingPoint ? (<div className={styles.isDownloadingInProcess}>An error occurred while downloading</div>)
                                : isFailure ? (<div className={styles.isDownloadingInProcess}>An error occurred</div>)
                                : (
                                  <button
                                    className={styles.downloadEvent}
                                    onClick={() => downloadPoint(point.pointId)}
                                    disabled={isFailure}
                                  >
                                      Download video
                                  </button>
                                )}
                          </Tooltip>
                        }
                        {(user.can.includes(UserCan.HIDE_POINTS) && !isButtonHidden)
                            ? <span className="btn-link text-danger" onClick={hidePointHandle}>Hide</span>
                            : null}
                        {(user.can.includes(UserCan.HIDE_POINTS) && isButtonHidden)
                            ? <span className="btn-link" onClick={showPointHandle}>Show</span>
                            : null}
                    </Col>
                </>
                )
            }
            {isAddAlarmIdPromptModalOpen
                && <AddAlarmIdModalPrompt
                    IsUserHasNotEnteredAllData={IsUserHasNotEnteredAllData}
                    onClosePrompt={() => setIsAddAlarmIdPromptModalOpen(false)}
                    openAddAlarmIdFormModal={() => setIsAddAlarmIdFormModalOpen(true)}
                    isEvent={isEvent}
                    handleLiveStream={showAlertHistoryVideo}
                />}
            {isAddAlarmIdFormModalOpen
                && <AddAlarmIdModalForm
                    formData={formData}
                    serial={serial}
                    firebaseDeviceId={deviceId}
                    setFormData={setFormData}
                    onCloseForm={() => setIsAddAlarmIdFormModalOpen(false)}
                    isEvent={isEvent}
              />}
        </div>
    )
}

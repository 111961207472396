import React from 'react';
import {useSelector} from "react-redux";
import {iFullStoreState, iList, iPerson} from "../../../../../shared/interfaces";
import {selectPeople} from "../../../../../stores/reducers/general-selectors";
import SearchGrid from "../../../../SearchGrid";
import {ReportSelectorLabel} from "../../../reports/ReportSelectorLabel";
import styles from './NewMaintenanceFormContacts.module.scss';

const NewMaintenanceFormContacts = ({handleClick, selectedPeople, handleResetAll}) => {
  const people = useSelector<iFullStoreState, iList<iPerson>>(selectPeople);

  return (
    <div className={styles.Contacts}>
      <div className={styles.ContactsHeader}>Send to</div>
      <SearchGrid<iPerson>
        searchFunction={({item, searchString}) => new RegExp(`${searchString}`, "ig").test(item.displayName)}
        items={Object.values(people)}
        onResetAll={handleResetAll}
        render={
          ({item}) => <ReportSelectorLabel key={item.id} className="report-send-to__label" itemId={item.id} active={selectedPeople[item.id] || false} title={item.displayName} url={item.photoURL} onClick={() => handleClick(item.id)}/>
        }
      />
    </div>
  )
}

export default NewMaintenanceFormContacts;

import React, {useEffect, useState} from 'react';
import DashboardBlock from "../../DashboardBlock";
import {Fa} from "../../elements/fa";
import {faArrowLeft, faEye, faPencilAlt, faSpinner} from "@fortawesome/fontawesome-free-solid";
import styles from './styles.module.scss';
import {useHistory} from 'react-router-dom';
import {dvirCloseDashboard, dvirOpenDashboard} from "../../../stores/reducers/dashboardInfo/AC";
import {useDispatch, useSelector} from "react-redux";
import {localStorage} from "../../../shared/storage";
import instance from "../../../api/instance";
import {DvirPagination} from "./DvirPagination";
import {iFullStoreState, iList, iPerson} from '../../../shared/interfaces';
import C from "../../../shared/constants";
import {Row} from "../../elements/flex";
import {dvirStatusMapping, formatDate} from "./utils";
import {DevicesDetailsContainer} from '../../../stores/reducers/devicesData';
import {is} from 'immutable';
import { equals } from 'ramda';

interface iDvir {
  id: string,
  device_key: string,
  firebase_key: string,
  created_at: string,
  person_key: string,
  status: string,
  vehicle_name: string,
  vehicle_vin: string
}

interface iDvirFilters {
  status: string,
  vehicle: string,
  driver: string,
  date: string
}

const Dvir = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const clientId = localStorage.get('active-client');
  const [page, setPage] = useState<number>(1);
  // const [limit, setLimit] = useState<number>(10);
  const [dvirs, setDvirs] = useState<iDvir[]>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<iDvirFilters>({
    status: '',
    vehicle: '',
    driver: '',
    date: ''
  });

  const people = useSelector<iFullStoreState>(state => state.general.people);

  const peopleArray = Object.values(useSelector<iFullStoreState, iList<iPerson>>(
    (s) => s.general.people,
    (l, r) => equals(l, r)
  )).map((person) => {
    return (
      {name: person.displayName, id: person.id}
    )
  });

  const devices = useSelector<iFullStoreState, DevicesDetailsContainer>(
    (s) => s.devicesData.devicesDetails,
    (l, r) => is(l, r)
  );
  const devicesArray = devices.valueSeq().toArray();

  const dvirStatusStyle: React.CSSProperties = {
    color: 'red'
  }

  const fetchDvirs = async (page: number = 1, limit: number = 5) => {
    const URL = 'api/dvir/get-dvirs';
    const body = {
      organization_key: clientId,
      page,
      limit
    }

    if (filters.status !== '') {
      body['dvir_status'] = [filters.status];
    }

    if (filters.vehicle !== '') {
      body['device_name'] = [filters.vehicle];
    }

    if (filters.driver !== '') {
      body['person_key'] = [filters.driver];
    }

    if (filters.date !== '') {
      const date = new Date(filters.date);

      const startOfDay = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0);
      const endOfDay = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59, 999);

      body['start_date'] = Math.round(startOfDay / 1000);
      body['end_date'] = Math.round(endOfDay / 1000);
    }

    try {
      setIsLoading(true);

      const {data} = await instance.post(URL, {...body});

      const {data: dvirs, total_pages: totalPages} = data;

      setTotalPages(totalPages);
      setDvirs(dvirs);

      setIsLoading(false);

      const dvir = dvirs[0];
    } catch ({message}) {
      console.log(message)
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);

    fetchDvirs(page);
  }

  const handleStatusFilter = (e) => {
    setFilters({...filters, status: e.target.value});
  }

  const handleDriverFilter = (e) => {
    setFilters({...filters, driver: e.target.value});
  }

  const handleVehicleFilter = (e) => {
    setFilters({...filters, vehicle: e.target.value});
  }

  const handleDateFilter = (e) => {
    setFilters({...filters, date: e.target.value});
  }

  useEffect(() => {
    setCurrentPage(1);
    fetchDvirs(1)
  }, [filters]);

  useEffect(() => {
    dispatch(dvirOpenDashboard());

    fetchDvirs();

    return () => {
      dispatch(dvirCloseDashboard());
    }
  }, []);

  return (
    <DashboardBlock>
      <div className={styles.DvirHeader}>
        <span className="no-print btn-link" style={{paddingLeft: 10, marginRight: 20}} onClick={() => history.goBack()}><Fa icon={faArrowLeft}/></span>
        <h2>DVIR Overview</h2>
        <div></div>
      </div>
      <div className={styles.DvirButtons}>
        <button onClick={() => history.push('/dvir/new')}>New Dvir</button>
        <button onClick={() => history.push('/dvir-forms')}>Forms</button>
      </div>
      {isLoading && (
        <Row justify='center' align='center'>
          <Fa icon={faSpinner} spin style={{ fontSize: 30 }}/>
        </Row>
      )}
      <table className={styles.DvirTable}>
        <thead>
          <tr>
            <th>#</th>
            <th>DVIR ID</th>
            <th>Driver</th>
            <th>Date</th>
            <th>Vehicle Name</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        <tr className={styles.FiltersRow}>
          <td>
          </td>
          <td>
          </td>
          <td>
            <select
              name='driver'
              value={filters.driver}
              onChange={handleDriverFilter}
            >
              <option value=''>Select Driver</option>
              {peopleArray.map(person => (
                <option key={person.id} value={person.id}>
                  {person.name}
                </option>
              ))}
            </select>
          </td>
          <td>
            <input
              type="date"
              name="date"
              className={styles.DatePicker}
              value={filters.date}
              onChange={handleDateFilter}
            />
          </td>
          <td>
            <select
              name='vehicleName'
              value={filters.vehicle}
              onChange={handleVehicleFilter}
            >
              <option value=''>Select Vehicle</option>
              {devicesArray.map(device => (
                <option key={device.id} value={device.name}>
                  {device.name}
                </option>
              ))}
            </select>
          </td>
          <td>
            <select
              name='status'
              value={filters.status}
              onChange={handleStatusFilter}
            >
              <option value=''>Select Status</option>
              <option value='completed'>Completed</option>
              <option value='maintenance-inspection'>Needs Maintenance</option>
              <option value='post-trip-inspection'>Needs Post-Trip Inspection</option>
              <option value='pre-trip-inspection'>Needs Pre-Trip Inspection</option>
            </select>
          </td>
        </tr>
        {!isLoading && dvirs && dvirs.length > 0 && (<>
          {dvirs.map((dvir, index) => {
            const person = people[dvir.person_key];

            const pencilStyles: React.CSSProperties = {
              color: dvir.status === 'completed' ? 'gray' : C.primaryColor.toString(),
              cursor: dvir.status === 'completed' ? 'not-allowed' : 'pointer',
            };

            return (
              <tr key={dvir.firebase_key}>
                <td>{index + 1}</td>
                <td>{dvir.id}</td>
                <td>{person?.displayName || ''}</td>
                <td>{formatDate(dvir.created_at)}</td>
                <td>{dvir.vehicle_name}</td>
                <td style={dvir.status !== 'completed' ? dvirStatusStyle : {}}>{dvirStatusMapping[dvir.status]}</td>
                <td className={styles.DvirItemActions}>
                  <Fa icon={faEye} style={{color: C.primaryColor.toString()}}
                      onClick={() => history.push(`/dvir/${dvir.firebase_key}`)}/>
                  <Fa icon={faPencilAlt} style={pencilStyles} onClick={() => {
                    if (dvir.status !== 'completed') history.push(`/dvir/${dvir.firebase_key}/update`);
                  }}/>
                </td>
              </tr>
            )
          })}
        </>)}
        </tbody>
      </table>
      {!isLoading && dvirs && dvirs.length > 0 && <DvirPagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
      />}
    </DashboardBlock>
  )
};

export default Dvir;
